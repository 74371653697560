<template>
  <div class="c-slider"
  @touchstart="onTouchStart" @mousedown="onTouchStart"
  @touchmove="onTouchMove" @mousemove="onTouchMove"
  @touchend="onTouchEnd" @mouseup="onTouchEnd">
    <div class="c-slider__track js-track">
      <div class="c-slider__progress js-progress" :style="progressStyle">
      </div>
      <div class="c-slider__pin js-pin" :style="pinStyle" role="button" tabindex="0" aria-label="Play/Pause">
        <span class="xp xp-spinner is-spinning xp--large" aria-hidden="true" v-if="isMediaProgressSlider && !isReady"></span>
        <span class="xp xp-play xp--large" aria-hidden="true" v-if="isMediaProgressSlider && isReady && !isPlaying"></span>
        <span class="xp xp-pause xp--large" aria-hidden="true" v-if="isMediaProgressSlider && isReady && isPlaying"></span>
      </div>
    </div>

  </div>
</template>

<script>
import throttle from '../util/throttle'

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    isMediaProgressSlider: {
      type: Boolean,
      default: false
    },
    isPlaying: {
      type: Boolean
    },
    isReady: {
      type: Boolean
    },
    pinSize: {
      type: Number,
      default: 15
    },
    trackSize: {
      type: Number,
      default: 15
    },
    orientation: {
      type: String,
      default: 'horizontal',
      validator: (v) => {
        return ['horizontal', 'vertical'].includes(v)
      }
    },
    length: {
      type: Number
    },
    seekable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      track: null,
      progress: null,
      pin: null,
      mouseDown: false,
      touchStartTime: 0,
      isSliderTap: false
    }
  },
  mounted () {
    this.onTouchStart = throttle(this.onTouchStart, 200)
    this.onTouchMove = throttle(this.onTouchMove, 50)
    this.onTouchEnd = throttle(this.onTouchEnd, 200)

    this.track = this.$el.querySelector('.js-track')
    this.progress = this.$el.querySelector('.js-progress')
    this.pin = this.$el.querySelector('.js-pin')
    if (this.isMediaProgressSlider) {
      this.$el.classList.add('c-slider--media-progress-slider')
    } else {
      this.pin.style.height = this.pinSize + 'px'
      this.pin.style.width = this.pinSize + 'px'
    }
    // this.track.addEventListener('click', this.onTrackClick, true)
    if (this.orientation === 'horizontal') {
      this.$el.classList.add('c-slider--horizontal')
      this.track.style.height = this.trackSize + 'px'
      this.progress.style.height = this.trackSize + 'px'
      const length = (this.length) ? this.length + 'px' : '100%'
      this.$el.style.width = length
      this.$el.style.height = this.pinSize + 'px'
    } else {
      this.$el.classList.add('c-slider--vertical')
      this.track.style.width = this.trackSize + 'px'
      this.progress.style.width = this.trackSize + 'px'
      const length = (this.length) ? this.length + 'px' : '100%'
      // this.progress.style.length = length
      this.$el.style.height = length
      this.$el.style.width = this.pinSize + 'px'
    }
  },
  beforeDestroy () {
    // this.track.removeEventListener('click', this.onTrackClick)
  },
  methods: {
    onTouchStart (e) {
      e.preventDefault() // To prevent the whole page scrolling on iPhone
      if (e.type === 'mousedown') this.mouseDown = true
      const path = e.path || (e.composedPath && e.composedPath())
      if (path.includes(this.pin)) {
        this.tapStartOffset = this.getEventOffset(e)
        this.isPinTap = true
      } else {
        if (this.seekable) {
          this.isSliderTap = true
          this.updateValue(this.getEventOffset(e))
          if (!this.isPlaying) this.$emit('pinTap')
        }
      }
      this.touchStartTime = Date.now()
      e.stopPropagation()
      this.$xp.navigation.showNav(true)
    },
    onTouchMove (e) {
      e.preventDefault() // To prevent the whole page scrolling on iPhone
      if (this.mouseDown || e.type === 'touchmove') {
        if (this.isPinTap || this.isSliderTap) {
          const currentOffset = this.getEventOffset(e)
          // accept tolerance value of 10
          if (Math.abs(currentOffset - this.tapStartOffset) > 500) {
            this.isPinTap = false
            console.log('onTouchMove() STOP isPinTap', e.type, e)
          } else {
            this.tapStartOffset = currentOffset
            if (this.seekable) {
              this.updateValue(this.getEventOffset(e))
            }
          }
        }
      }
      e.stopPropagation()
      this.$xp.navigation.showNav(true)
    },
    onTouchEnd (e) {
      if (e.type === 'mouseup') this.mouseDown = false
      if (this.isPinTap) {
        const wasSeeking = Date.now() - this.touchStartTime > 400
        console.log('wasSeeking:', wasSeeking, ', touchTime:', Date.now() - this.touchStartTime)
        if (!wasSeeking || !this.isPlaying) {
          console.log('pin tap')
          this.$emit('pinTap')
        }
      }
      this.isPinTap = false
      this.isSliderTap = false
      e.stopPropagation()
    },
    getEventOffset (e) {
      let offset
      if (e.touches) {
        offset = this.getTouchOffset(e)
      } else {
        offset = this.getMouseOffset(e)
      }
      return offset
    },
    getTouchOffset (e) {
      const boundingRect = this.$el.getBoundingClientRect()
      if (this.orientation === 'horizontal') {
        return e.touches[0].clientX - boundingRect.left
      } else {
        // console.log(`getTouchOffset: ${boundingRect.height} - ${e.touches[0].clientY} + ${boundingRect.top} = ${boundingRect.height - e.touches[0].clientY + boundingRect.top}`)
        return boundingRect.height - e.touches[0].clientY + boundingRect.top
      }
    },
    getMouseOffset (e) {
      const boundingRect = this.$el.getBoundingClientRect()
      if (this.orientation === 'horizontal') {
        return e.clientX - boundingRect.left
      } else {
        return boundingRect.height - (e.clientY - boundingRect.top)
      }
    },
    updateValue (offset) {
      const value = offset / this.getTrackLength()
      // console.log(`onTouchMove value: ${offset} / ${this.getTrackLength()} = ${value}`)
      if (value < 0) {
        this.$emit('update:value', 0)
      } else if (value >= 0 && value <= 1) {
        this.$emit('update:value', value)
      } else if (value > 1) {
        this.$emit('update:value', 1)
      }
    },
    getTrackLength () {
      if (this.track) {
        if (this.orientation === 'horizontal') {
          return this.track.clientWidth
        } else {
          return this.track.clientHeight
        }
      }
      return 0
    }
  },
  computed: {
    progressStyle () {
      if (this.orientation === 'horizontal') {
        return { width: (this.getTrackLength() * this.value) + 'px' }
      } else {
        return { height: (this.getTrackLength() * this.value) + 'px' }
      }
    },
    pinStyle () {
      if (this.orientation === 'horizontal') {
        return { left: (this.getTrackLength() * this.value) + 'px' }
      } else {
        return { bottom: (this.getTrackLength() * this.value) + 'px' }
      }
    }
  }
}
</script>
