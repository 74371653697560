<template>
    <div class="c-video-player" :style="{ backgroundImage: 'url(' + this.poster + ')' }" :class="{'landscape': this.$xp.screen.orientation==='landscape-primary'}">
        <div class="c-video-player__ios-play" v-if="!playing && isIos" @click="onClickFullscreenPlay" >
              <span class="c-panorama__hotspot-icon--default c-panorama__hotspot-icon xp xp--huge" aria-hidden="true"></span>
        </div>
        <div class="c-video-player__controls" :class="{'is-hidden': ($xp.navigation.isNavHidden && playing) || !showControls}" id="mediaplayer" v-if="!isIos">
            <div class="c-video-player__wrapper">
                <div class="c-video-player__duration" role="progressbar" :aria-valuenow="duration*progress" aria-valuemin="0" :aria-valuemax="duration" :title="$t('global.remainingTime')" :aria-label="$t('global.remainingTime')">
                    {{ (progress * duration) - duration | formatPlayTime }}
                </div>
              <vue-ellipse-progress
                :angle="-90"
                animation="default 100 0"
                color="#fff"
                dash="0"
                emptyColor="rgba(0,0,0,.3)"
                emptyThickness="6"
                fontColor="white"
                fontSize="2rem"
                :legend="false"
                legendClass="legend-custom-style"
                line="butt"
                lineMode="in-over"
                :loading="false"
                :noData="false"
                :progress="progress*100"
                :size="90"
                :thickness="6">

                <div slot="legend-value"></div>
                <div slot="legend-caption">
                  <div class="c-video-player__play">
                    <button class="c-video-player__button" :class="{'not-ready': !this.ready }" @click="togglePlayback()" tabindex="0" :title="$t('global.play')" :aria-label="$t('global.play')">
                      <span class="xp xp-pause xp--large" aria-hidden="true" v-if="playing"></span>
                      <span class="xp xp-fast-rewind xp--large" aria-hidden="true" v-else-if="showPlayAgain"></span>
                      <span class="xp xp-video-audio-play xp--large" aria-hidden="true" v-else></span>
                    </button>
                  </div>
                </div>

              </vue-ellipse-progress>
              <div class="c-video-player__ffrew">
                <button class="c-video-player__button" @click="stepBackward()" tabindex="0" :title="$t('global.rewind')" :aria-label="$t('global.rewind')">
                  <span class="xp xp-fast-rewind xp--large" aria-hidden="true"></span>
                </button>
                <button class="c-video-player__button" @click="stepForward()" tabindex="0" :title="$t('global.forward')" :aria-label="$t('global.forward')">
                  <span class="xp xp-fast-forward xp--large" aria-hidden="true"></span>
                </button>
              </div>
            <button class="c-video-player__subtitles-button" @click="onSubtitlesClick()" :tabindex="subtitles.BinaryID ? 0 : -1" v-if="subtitles && subtitles.BinaryID!==null" :title="$t('global.showSubTitles')">
              <i class="xp xp--large xp-subtitles" aria-hidden="true" :class="{'xp-subtitles-off': showSubtitles }" ></i>
            </button>
            <div class="c-video-player__volume-wrapper">
              <button class="c-video-player__volume-button" @click="showVolumeControl = !showVolumeControl" tabindex="0" :aria-label="$t('global.setVolume')" v-if="$xp.media.allowUIVolumeControl">
                  <i class="xp xp-volume-up xp--large" aria-hidden="true" v-if="$xp.media.volume >= 0.5"></i>
                  <i class="xp xp-volume-down xp--large" aria-hidden="true" v-if="$xp.media.volume < 0.5 && $xp.media.volume !== 0"></i>
                  <i class="xp xp-volume-off xp--large" aria-hidden="true" v-if="$xp.media.volume === 0" name="$xp.media.volume-off"></i>
              </button>
              <div class="c-video-player__volume-slider" v-if="showVolumeControl">
                  <slider v-bind:value.sync="playbackVolume" :orientation="this.$xp.screen.orientation==='landscape-primary'?'horizontal':'vertical'" :trackSize=10 :length="200"></slider>
              </div>
            </div>
          </div>
        </div>
    </div>

</template>
<script>
import EventHub from '../util/EventHub'
import Slider from './Slider.vue'
import lipSync from '../mixins/lipSync'

export default {
  mixins: [lipSync],
  props: {
    source: {
      default: ''
    },
    poster: {
      default: ''
    },
    autoPlay: {
      default: false
    },
    loop: {
      default: false
    },
    muted: {
      default: false
    },
    showControls: {
      default: true
    },
    binaryId: null,
    subtitles: null,
    lipSyncUrl: {
      type: String,
      default: null,
      validator: value => {
        return value === null || ((typeof value === 'string' || value instanceof String) && value.startsWith('ws://'))
      }
    }
  },
  data () {
    return {
      showVolumeControl: false,
      playing: false,
      showPlayAgain: false,
      progress: 0,
      duration: 0,
      ready: false,
      isSeeking: false,
      sliderVirtualPosition: 0,
      isIos: this.$xp.device.platform === 'ios' || this.$xp.device.isIPhoneOrPad,
      showSubtitles: false,
      textTrackCueLine: 0
    }
  },
  computed: {
    track: {
      get: function () {
        if (this.isSeeking && !this.isLipSync) {
          return this.sliderVirtualPosition
        } else {
          return this.progress
        }
      },
      set: function (v) {
        if (this.video) {
          if (!Number.isNaN(v)) {
            this.sliderVirtualPosition = v
            this.video.currentTime = v * this.duration
          } else {
            console.log('vPlayer invalid time :' + v)
          }
        }
      }
    },
    playbackVolume: {
      get: function () {
        return parseFloat(this.$xp.media.volume)
      },
      set: function (v) {
        this.$xp.media.setGlobalVolume(v)
        this.video.volume = v
      }
    },
    isLipSync () {
      return this.lipSyncUrl != null
    }
  },
  mounted () {
    // const oldVideo = this.$el.querySelector('video')
    // if (oldVideo) {
    //   console.log('OLD VIDEO HEREEEEEEEEEEEEEEEEEEEEEEEE!!!!!!!!!!!!!!')
    //   this.$el.removeChild(oldVideo)
    // }
    this.IsIos = this.$xp.device.platform === 'ios' || this.$xp.device.isIPhoneOrPad
    console.log(this.$xp.device.platform, this.$xp.device.isIPhoneOrPad, this.IsIos, this.$xp.device.isSafari)
    this.$xp.device.getRemappedUrl(this.source).then((remappedUrl) => {
      if (this.$xp.device.platform === 'ios') {
        if (window.XpedeoPreloader) {
          remappedUrl = window.XpedeoPreloader.convertProxyUrl(remappedUrl)
        }
      }
      console.log('lipsyncUrl', this.lipSyncUrl)
      this.video = document.createElement('video')
      this.video.classList.add('c-video-player__element')
      // if (window.device.platform.toLowerCase() !== 'ios')
      this.video.controls = false
      this.video.src = remappedUrl
      this.video.type = 'audio/mp4'
      this.video.loop = this.loop
      console.log('showControls', this.showControls)
      this.video.showControls = this.showControls
      this.video.crossOrigin = 'anonymous'
      this.video.poster = this.poster
      this.video.disableRemotePlayback = true

      if (this.muted) {
        this.volumeBeforeMute = this.$xp.media.volume
        this.video.volume = 0
      } else {
        this.video.volume = this.$xp.media.volume
      }
      this.video.load()
      if (this.isIos) { this.video.autoPlay = false }

      if (this.$el.prepend) { this.$el.prepend(this.video) } else {
        this.$el.insertBefore(this.video, this.$el.firstChild)
      }
      this.video.addEventListener('loadedmetadata', this.onLoadedMetadata)
      if (this.isIos) {
        this.video.addEventListener('webkitbeginfullscreen', this.enteredFullscreen)
        this.video.addEventListener('webkitendfullscreen', this.exitedFullscreen)
      }
      this.video.addEventListener('canplaythrough', this.onCanPlayThrough)
      this.video.addEventListener('play', this.onVideoPlay)
      this.video.addEventListener('pause', this.onVideoPause)
      this.video.addEventListener('timeupdate', this.onTimeUpdate)
      this.video.addEventListener('ended', this.onVideoEnded)
      this.video.addEventListener('seeking', this.onSeeking)
      this.video.addEventListener('seeked', this.onSeeked)
      if (this.subtitles && this.subtitles.BinaryID !== null) {
        if (this.$xp.settings.mergedSettings.showSubtitlesDefault) {
          this.showSubtitles = true
        } else {
          this.showSubtitles = this.$xp.media.showSubtitles
        }
      }
    })
    EventHub.Bus.$on(EventHub.TYPES.START_PLAY_MEDIA, (uid) => {
      if (this.video && this._uid !== uid) {
        this.video.pause()
      }
    })
    window.addEventListener('keyup', this.onKeyEvent)

    // temporary workaround for firefox on windows, video takes too long to start
    console.log('isFirefox :', this.$xp.device.isFirefox, ' isWindows :', this.$xp.device.isWindows)
    if (this.$xp.device.isFirefox && this.$xp.device.isWindows) {
      const tryTillReady = setInterval(() => {
        if (this.ready) {
          console.log('video is ready')
          clearInterval(tryTillReady)
        } else {
          console.log('reloading video, firefox windows video problem')
          this.video.load()
        }
      }, 6000)
    }
    // if (this.isIos) {
    //   if (window.StatusBar) {
    //     if (window.StatusBar.isVisible) {
    //       console.log('hide while creating video')
    //       window.StatusBar.hide()
    //     } else {
    //       console.log('not visible')
    //     }
    //   }
    // }
  },
  beforeDestroy () {
    if (this.isIos) {
      if (!this.$xp.settings.mergedSettings.showSubtitlesDefault) {
        if (this.video.textTracks[0].mode === 'showing' && !this.$xp.media.showSubtitles) {
          this.$xp.media.setShowSubtitles(true)
        } else if (this.video.textTracks[0].mode === 'hidden' && this.$xp.media.showSubtitles) {
          this.$xp.media.setShowSubtitles(false)
        }
      }
      console.log('in ios sub condition, b4destroy in vPlayer')
    }
    this.video.autoplay = false
    this.video.pause()
    this.$emit('media-session-ended')

    if (this.isLipSync) {
      this.stopLipSync()
      this.$xp.wifi.releaseWifiConnectionOn('videoSync')
    }
    this.video.removeEventListener('loadedmetadata', this.onLoadedMetadata)
    this.video.removeEventListener('canplaythrough', this.onCanPlayThrough)
    this.video.removeEventListener('play', this.onVideoPlay)
    this.video.removeEventListener('pause', this.onVideoPause)
    this.video.removeEventListener('timeupdate', this.onTimeUpdate)
    this.video.removeEventListener('ended', this.onVideoEnded)
    this.video.removeEventListener('seeking', this.onSeeking)
    this.video.removeEventListener('seeked', this.onSeeked)

    this.video.removeEventListener('keyup', this.onKeyEvent)

    EventHub.Bus.$off(EventHub.TYPES.START_PLAY_MEDIA, this.onStartPlayMedia)
    if (this.muted) {
      this.$xp.media.setGlobalVolume(this.volumeBeforeMute)
    }
    this.video.parentNode.removeChild(this.video)
    this.video.removeAttribute('src')
    this.video.load()
    this.video = null
    // if (this.isIos) {
    //   if (window.StatusBar.isVisible) {
    //     console.log('StatusBar i s visible, hiding it.')
    //     window.StatusBar.hide()
    //   } else { console.log('not visible') }
    // }
  },
  methods: {
    onLoadedMetadata () {
      console.log('onLoadedMetadata', this.video.duration, this.video.currentTime, this.video.play)
      if (this.video) {
        this.duration = this.video.duration
      }
      if (this.subtitles && this.subtitles.BinaryID !== null) {
        this.loadSubtitles()

        const subButton = document.getElementsByClassName('c-video-player__subtitles-button')
        if (this.showSubtitles) { subButton[0].classList.add('c-subButton-active') } else { subButton[0].classList.add('c-subButton-inactive') }
      }
    },
    loadSubtitles () {
      const ttrack = document.createElement('track')
      ttrack.src = this.subtitles.Url
      ttrack.kind = 'subtitles'
      ttrack.srclang = this.subtitles.srclang
      ttrack.label = this.subtitles.label
      ttrack.default = true

      const oVideo = this.video
      ttrack.addEventListener('load', () => {
        console.log('subs are loaded')
        const aCues = oVideo.textTracks[0].cues
        for (let n = 0; n < aCues.length; n++) {
          aCues[n].line = -1
          aCues[n].size = 90
        }
        if (this.showSubtitles) {
          console.log('subs are showing')
          this.mode = 'showing'
        } else {
          console.log('subs are hiding')
          this.mode = 'hidden'
        }
        if (this.isIos) {
          console.log('in load subs', oVideo.webkitClosedCaptionsVisible)
          oVideo.webkitClosedCaptionsVisible = this.showSubtitles
          console.log('in load subs', oVideo.webkitClosedCaptionsVisible)
        }
      })
      ttrack.className = 'subtitlesTrack'
      this.video.appendChild(ttrack)

      const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification))
      if (isSafari || this.isIos) {
        console.log('identified Safari')
        this.video.classList.add('stupidSafariSubs')
      }
    },
    async onCanPlayThrough () {
      if (this.video) {
        if (this.isLipSync) {
          if (!this.ready) {
            await this.$xp.wifi.lockWifiConnectionOn('videoSync')
            await this.$xp.network.waitForOnline()
            this.startLipSync(this.video)
          }
        } else {
          console.log('onCanPlayThrough')
          const shouldRestoreState = !this.ready && this.$route.query.ref !== 'beacon'
          console.log('shouldRestoreState: ' + shouldRestoreState)
          const historyCurrentTime = this.$xp.history.historyParams['currentTime' + this.binaryId]
          console.log('restore time to = ' + historyCurrentTime)
          if (shouldRestoreState && historyCurrentTime) {
            console.log('restore time to2 = ' + historyCurrentTime)
            this.video.currentTime = historyCurrentTime
            if (this.video.currentTime >= this.video.duration - 1) {
              this.showPlayAgain = true
            }
          }
          const historyPlayingState = this.$xp.history.historyParams['isPlaying' + this.binaryId]
          let videoPromise
          if (shouldRestoreState && typeof historyPlayingState !== 'undefined') {
            if (historyPlayingState) { videoPromise = this.video.play() } else { videoPromise = this.video.pause() }
          } else if (!this.ready && this.video && this.autoPlay) {
            videoPromise = this.video.play()
          }
          if (videoPromise !== undefined) {
            videoPromise.catch(error => {
              console.log('Cannot Autoplay Video \n', error)
              // Auto-play was prevented
              // Show a UI element to let the user manually start playback
            })
          }
          this.ready = true
        }
      }
    },
    onVideoPlay () {
      // console.log('onVideoPlay')
      // console.log(this.video)
      this.setPlayingState(true)
      EventHub.Bus.$emit(EventHub.TYPES.START_PLAY_MEDIA, this._uid)
      this.$emit('media-playing')
    },
    onVideoPause () {
      // console.log('onVideoPause')
      this.setPlayingState(false)
      this.exitFullscreen()
    },
    setPlayingState (isPlaying) {
      this.playing = isPlaying
      this.$xp.media.setIsMediaPlaying(isPlaying)
      this.$xp.history.setHistoryParam(parseInt(this.$route.params.id), 'isPlaying' + this.binaryId, isPlaying)
    },
    onTimeUpdate () {
      // console.log('onTimeUpdate')
      if (this.video && this.video.currentTime && this.ready) {
        this.progress = this.video.currentTime / this.video.duration
        if (Math.floor(this.video.currentTime) % 10 === 0) {
          // postpone dimming every 10 seconds
          this.$xp.screen.postponeDim()
        }
        this.$xp.history.setHistoryParam(parseInt(this.$route.params.id), 'currentTime' + this.binaryId, this.video.currentTime)
      } else {
        // if (!this.isLipSync)
        this.progress = 0
      }
    },
    onVideoEnded () {
      console.log('onVideoEnded')
      // record something if needed
      if (this.video && !this.loop) {
        this.video.pause()
        // Jump a little bit back, so on going back to this page onVideoEnded() is not called automatically again
        this.video.currentTime -= 0.1
        this.onTimeUpdate()
        this.setPlayingState(false)
        if (this.$xp.navigation.childPagesMenuItems.length && this.$xp.navigation.isChildPagesMenuHidden && !this.$xp.settings.mergedSettings.navigation.mediaIgnoresContextMenu) {
          this.$xp.navigation.toggleChildPagesMenu()
          this.showPlayAgain = true
        } else if ((this.$xp.navigation.childPagesMenuItems.length === 0 || this.$xp.settings.mergedSettings.navigation.mediaIgnoresContextMenu) && this.$xp.history.nextPageId && this.$xp.settings.mergedSettings.navigation.nextPageOnMediaEnd) {
          this.$router.push('/page/' + this.$xp.history.nextPageId)
        } else if (this.$xp.navigation.childPagesMenuItems.length === 0 && this.$xp.settings.mergedSettings.navigation.backOnVideoEnd) {
          this.$xp.history.back()
        } else {
          // Erneut abspielen anbieten
          this.showPlayAgain = true
        }
      }
    },
    onSeeking () {
      // console.log('onSeeking')
      this.isSeeking = true
    },
    onSeeked () {
      // console.log('onSeeked')
      this.isSeeking = false
    },
    onStartPlayMedia (uid) {
      if (this.video && this._uid !== uid) {
        this.video.pause()
      }
    },
    togglePlayback () {
      console.log('togglePlayback', this.playing, this.showPlayAgain)
      if (this.video && this.ready) {
        if (!this.playing && this.showPlayAgain) {
          this.video.currentTime = 0
          this.showPlayAgain = false
          this.video.play()
        } else if (!this.playing) {
          this.video.play()
        } else {
          this.video.pause()
        }
      }
    },
    stepForward () {
      console.log('Button: Video StepForward')
      this.video.currentTime += 15
      this.onTimeUpdate()
    },
    stepBackward () {
      console.log('Button: Video StepBackward')
      this.video.currentTime -= 15
      this.showPlayAgain = false
      this.onTimeUpdate()
    },
    onClickFullscreenPlay () {
      if (this.video.webkitDisplayingFullscreen) {
        console.log('full screen')
        // this.video.webkitExitFullscreen()
      } else {
        console.log('not full screen')

        // this.video.webkitRequestFullScreen()

        if (this.video.webkitEnterFullscreen) {
          this.video.webkitEnterFullscreen()
        } else if (this.video.webkitRequestFullscreen) {
          this.video.webkitRequestFullscreen()
        }
      }
      this.togglePlayback()
    },
    exitFullscreen () {
      if (this.isIos) {
        if (this.video.webkitExitFullscreen()) {
          this.video.webkitExitFullscreen()
        }
      }
    },
    enteredFullscreen () {
      console.log('now full screen')
      if (this.$el.webkitFullscreenElement) { console.log(this.$el.webkitFullscreenElement) }
    },
    exitedFullscreen () {
      console.log('exited fullscreen')
      if (this.$el.webkitFullscreenElement) { console.log(this.$el.webkitFullscreenElement) }
    },
    onKeyEvent (e) {
      const nKey = e.keyCode
      // console.log("onKeyEvent " + nKey);
      switch (nKey) {
        case 27: // ESC
          console.log('KeyEvent: Audio StopPlay')
          this.video.pause()
          break

        case 32: // Space
        case 75: // K
          console.log('KeyEvent: Video TogglePlay')
          this.togglePlayback()
          break

        case 37: // <-
          console.log('KeyEvent: Video Backward')
          this.video.currentTime -= 15
          this.onTimeUpdate()
          break

        case 39: // ->
          console.log('KeyEvent: Video Forward')
          this.video.currentTime += 15
          this.onTimeUpdate()
          break
        /** NO NEED for volum control in browser */
        // case 38: // arrow up
        //   if (this.$xp.media.showVolumeControl) {
        //     console.log('KeyEvent: Video Volume Up', this.playbackVolume)
        //     this.playbackVolume = Math.min(this.playbackVolume + 1, 200)
        //   }
        //   break

        // case 40: // arrow down
        //   if (this.$xp.media.showVolumeControl) {
        //     console.log('KeyEvent: Video Volume Down', this.playbackVolume)
        //     this.playbackVolume = Math.max(this.playbackVolume - 1, 0)
        //   }
        //   break
      }
    },
    onSubtitlesClick () {
      if (this.showSubtitles) {
        this.showSubtitles = false
        this.video.textTracks[0].mode = 'hidden'
      } else {
        this.showSubtitles = true
        this.video.textTracks[0].mode = 'showing'
      }
      if (!this.$xp.settings.mergedSettings.showSubtitlesDefault) {
        this.$xp.media.setShowSubtitles(this.showSubtitles)
      }
    }
  },
  components: {
    Slider
  },
  watch: {
    '$xp.navigation.isNavHidden': function (value) {
      if (value === true) {
        this.showVolumeControl = false
      }
    },
    progress: function () {
      this.$emit('media-progress-changed')
      if (this.subtitles && this.subtitles.BinaryID && this.showSubtitles) {
        const nCueLine = (this.$xp.navigation.isNavHidden ? 16 : 16) // hier kann Position der Untertitel angepasst werden. 16 = ganz unten
        if (nCueLine !== this.textTrackCueLine) {
          // console.log('set Subtitles to line ', nCueLine)
          this.video.textTracks[0].mode = 'hidden'
          for (let i = 0; i < this.video.textTracks[0].cues.length; i++) {
            this.video.textTracks[0].cues[i].line = nCueLine
          }
          this.video.textTracks[0].mode = 'showing'
          this.textTrackCueLine = nCueLine
        }
      }
    },
    showSubtitles: function () {
      if (this.video.textTracks[0]) {
        if (this.showSubtitles) {
          this.video.textTracks[0].mode = 'showing'
          // console.log('in showsubs watch', value)
        } else {
          this.video.textTracks[0].mode = 'hidden'
          // console.log('in showsubs watch', value)
        }
      }
    },
    playing: function () {
      if (this.subtitles) {
        if (this.subtitles.BinaryID) {
          if (this.video.textTracks[0].mode === 'showing' && !this.showSubtitles) {
            this.video.textTracks[0].mode = 'hidden'
          } else if (this.video.textTracks[0].mode === 'hidden' && this.showSubtitles) {
            this.video.textTracks[0].mode = 'showing'
          }
        }
      }
    }
  }
}
</script>
